// TODO: Retrieve most settings from server

interface IFrontendConfig {
  language: string;
  organization: string;
  appBarTitle: string;
  welcomeHint?: string; // Will be shown on the first welcome page as emphasized text
  privacyInfo: {
    entitiesWithDataAccess: string[];
    privacyStatementUrl?: string;
  };
  errorContacts: {
    name: string;
    phone: string;
  }[];
}

const FrontendConfig: IFrontendConfig = {
  language: 'de',
  organization: 'Schützengilde Königreich Flieden',
  welcomeHint:
    'Die Teilnahme am Schießbetrieb ist nur mit einer waffenrechtlichen Erlaubnis (WBK, Jagdschein, ...) oder in Begleitung einer Person mit dieser Erlaubnis möglich!',
  appBarTitle: '',
  privacyInfo: {
    entitiesWithDataAccess: [
      'Der Vorstand',
      'Standaufsichten und Schießleiter',
      'Für die Wartung dieses Buchungsdienstes zuständige Mitglieder',
    ],
    privacyStatementUrl: 'https://sgi-flieden.de/Datenschutz',
  },
  errorContacts: [
    {
      name: 'Erwin Haubner',
      phone: '0170 83 91 620',
    },
    {
      name: 'Lars Kaufmann',
      phone: '0170 81 07 346',
    },
  ],
};

export default FrontendConfig;
